import $ from 'jquery';
import './style.css';

window.onload = function() {
  redirectMe();
}

function getUrlParameter(paramName) {
  let pageUrl = window.location.search.substring(1),
      urlVariables = pageUrl.split('&'),
      parameterName,
      i;
  
  for (i = 0; i < urlVariables.length; i++) {
    parameterName = urlVariables[i].split('=');
    
    if (parameterName[0] === paramName) {
      return parameterName[1] === undefined ? "" : decodeURIComponent(parameterName[1]).trim();
    }
  }
  
  return ""
}

function getLanguage() {
  let language = getUrlParameter("lang")
  if (language.length === 0) {
    if (navigator.languages) {
      language = navigator.languages[0].toLowerCase();
    } else if (navigator.language) {
      language = navigator.language.toLowerCase();
    }
  }
  
  if (language.indexOf("de") !== -1) {
    return "de"
  }
  else if (language.indexOf("nl") !== -1) {
    return "nl"
  }
  
  return "en"
}

function redirectMe() {
  let redirectUrl = process.env.REDIRECT_URL_IAMC
  let redirectDefault = process.env.REDIRECT_DEFAULT
  
  let redirectParam = getUrlParameter("redirect")
  if (redirectParam.length === 0) {
    redirectParam = redirectDefault
  }
  
  window.location.replace(redirectUrl + "&state=" + redirectParam + "&lang=" + getLanguage())
}

// Login buttons
$("#link_iamc").click(function (event) {
  event.preventDefault();
  redirectMe();
});